import React from 'react';
import './TosAcceptance.scss';
import {translateService} from "../../services/translateService";
import apiService from "../../services/apiService";
import ApiModal from "../apiModal/apiModal";
import {User} from "../../interfaces/user";
import {Dispatch, store} from "../../reduxStore";
import {connect} from "react-redux";
import ResponseModal from "../responseModal/ResponseModal";
import {analyticsService} from "../../services/analyticsService";

const CustomerAgreeCheckboxInput: React.FunctionComponent<{ label: any; errorString: string; setValue: (_: boolean) => void;
    fieldName: string; checked: boolean} > = ({label, setValue, fieldName, checked }) => {
    // agree checkbox has separate input component to make use of 'defaultChecked' only instead of 'checked' --> to avoid uncontrolled input + classname attribute for normal checkboxes allowed
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.currentTarget.checked);
    };

    return (
        <form className='service-agree-card'>
            <div className={'serviceAgree'}
                 style={{ border: "1px solid #000000", padding: 10, marginBottom: 5}}>
                <input
                    type='checkbox'
                    name={fieldName}
                    defaultChecked={checked}
                    onChange={handleChange} />
                <label>{label}</label>
            </div>
        </form>
    )
}

interface TosAcceptanceProps {
    crmService: any;
}

interface DispatchFromProps {
    // Redux dispatch options referenced as props
    onSetUser: (user: User) => void;
}

const TosAcceptance = (props: DispatchFromProps & TosAcceptanceProps)=> {
    const [policyConditionsAndReturn, setPolicyConditionsAndReturn] = React.useState(false);
    const [liability, setLiability] = React.useState(false);
    const [isUpdating, setIsUpdating] = React.useState('');
    const [responseModal, setResponseModal] = React.useState({open: false, text: '', buttonText: ''});
    const launcherMode = sessionStorage.getItem('L-M') ? sessionStorage.getItem('L-M') === '1' : false;

    const updateTosAccepted = () => {
        if (policyConditionsAndReturn && liability) {
            let updatedParameter = {hasAcceptedTwoFourGoTOS: true};
            setIsUpdating(translateService.translate('profileCompletenessPhase.messages.savingUser'));
            apiService.saveUser(updatedParameter)
                .then(() => {
                    apiService.fetchUser().then((newUserRes: User) => {
                        props.onSetUser(newUserRes);
                    }).catch(() => {
                        setResponseModal({open: true, text: translateService.translate('profileCompletenessPhase.messages.savingFailed'), buttonText: 'OK'});
                    }).finally(() => {
                        setIsUpdating('');
                    });
                })
                .catch((res: any) => {
                    console.log("Profile update failed: ", res);
                    setIsUpdating('');
                    setResponseModal({open: true, text: translateService.translate('profileCompletenessPhase.messages.savingFailed'), buttonText: 'OK'});
                });
        }
    }

    const logOut = () => {
        analyticsService.logEvent("Logoff");
        props.crmService.userLogout();
        localStorage.removeItem("GoNowToken");
        localStorage.removeItem("GoNowStore");
        props.onSetUser({} as User);
        store.dispatch({ type: "LOGOUT" });
    }

    return(
        <div className='tos-container'>
                <ResponseModal response={responseModal}
                               closeFunc={() => { setResponseModal({open: false, text: '', buttonText: ''})}} />
                <ApiModal isOpen={!!isUpdating} message={translateService.translate('apiMessage.updatingUser')} />
                <h1 className='center-text-or-btn'>{translateService.translate("userScreen.acceptTOS")}</h1>
                <br/>
                <CustomerAgreeCheckboxInput label={
                    <div>
                        <div className='mb-3'>
                            {translateService.translate("userScreen.gdpr")}
                            <a target="_blank" rel="noopener noreferrer"
                               href={"https://www.24rent.fi/tietosuojaseloste/"}>
                                {translateService.translate("userScreen.gdpr-link")}</a>).
                            <strong>*</strong>
                        </div>
                        <div className='mb-3'>{translateService.translate("userScreen.agree")}
                            <a target="_blank" rel="noopener noreferrer"
                               href={"https://www.24rent.fi/vuokrausehdot/"}>
                                {translateService.translate("userScreen.agree-link")}
                            </a>{" )"}.<strong>*</strong>
                        </div>
                        <div className='mb-3'>
                            {translateService.translate("userScreen.understand")}
                            <a target="_blank" rel="noopener noreferrer"
                               href={"https://24rent.zendesk.com/hc/fi/categories/115000177154-Mit%C3%A4-yhteisk%C3%A4ytt%C3%B6autoilu-on-"}>
                                {translateService.translate("userScreen.understand-link")}</a>).
                            <strong>*</strong></div>
                    </div> }
                                            fieldName={''} errorString={""} setValue={setPolicyConditionsAndReturn} checked={false} />

                <CustomerAgreeCheckboxInput label={
                    <div className={'mb-3'}>
                      <span>{translateService.translate("userScreen.fairUnderstand")}
                          <a target="_blank" rel="noopener noreferrer" href={'https://www.24rent.fi/en/fair-en/'}>
                              {translateService.translate("userScreen.fairUnderstand-link")}</a>.<strong>*</strong></span>
                    </div>
                } fieldName={"acceptField4"} errorString={""} setValue={setLiability} checked={false}/>

                <div className='center-text-or-btn'>
                    { !(liability && policyConditionsAndReturn) ?
                        <span className='continue-btn-error'> {translateService.translate('userScreen.acceptToContinue')} </span>
                        : null
                    }

                    <button disabled={!(liability && policyConditionsAndReturn)}
                            onClick={() => {updateTosAccepted()} }
                            className={'btn-lg normal primary fw-bold'}>
                        {translateService.translate('userScreen.accept')}
                    </button>
                    { !launcherMode ?
                        <button onClick={() => {logOut()}}
                                className={'btn-lg normal primary fw-bold'}>
                            {translateService.translate('userScreen.cancel')}
                        </button>
                        : null }
                </div>

            </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        crmService: state.map.crmService,
    }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => {
    return {
        onSetUser: (user: User) => {
            dispatch({
                type: 'STORE_USER',
                payload: user,
            });
        },
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(TosAcceptance);