import { V3User } from "../interfaces/v3/user";
import { store } from "../reduxStore";

export const login = {
    fetchLogin: (phoneNumber: string) => fetchCustom('/login/send-2fa-pin',
        {
            method: "POST",
            body: JSON.stringify({ phoneNumber, createIfCustomerNotFound: true })
        }),

    fetchTwoFactor: (pin: string, phoneNumber: string) => fetchCustom('/login/try-2fa-pin',
        {
            method: "POST",
            body: JSON.stringify({ phoneNumber, pin, environment: "gonow" }),
        },
    ),
    tryAuthHash: (authenticationHash: string, customerId: number) => fetchCustom('/login/try-authentication-hash',
        {
            method: "POST",
            body: JSON.stringify({ authenticationHash, customerId, environment: "gonow" }),
        },
    ),
}

export const user = {
    fetchReservations: () => fetchCustom('/reservations',
        {
            // method: "POST",
            // body: JSON.stringify({ phoneNumber, createIfCustomerNotFound: true })
        }),
    fetchV3User: () => fetchCustom('/customers/me',
        {
            // method: "POST",
            // body: JSON.stringify({ phoneNumber, createIfCustomerNotFound: true })
        }),
    saveV3User: (partialv3user: any) => fetchCustom('/customers/me',
        {
            method: "PATCH",
            body: JSON.stringify(partialv3user)
        }),
    fetchQualification: () => fetchCustom('/customers/profile-completeness',
        {
            // method: "POST",
            // body: JSON.stringify({ phoneNumber, createIfCustomerNotFound: true })
        }),
    sendVerificationEmail: () => fetchCustom('/customers/me/verify?reservation_id=210071&24go=1',
        {
            method: "POST",
            body: JSON.stringify({})
        }),
    getVerificationLink: () => fetchCustom('/customers/me/identity-verification-link-twofour-go'),
    sendPictureForLicense: (
        customerId: number,
        reservationId: number,
        imageData: string,
        exifData?: any[]
    ) => fetchCustom('/customers/me/drivers-license?24go=1',
        {
            method: "POST",
            body: JSON.stringify({ photo: imageData, exifData, reservationId })
        }),
    sendPictureForUser: (
        customerId: number,
        reservationId: number,
        imageData: string,
        exifData?: any[]
    ) => fetchCustom('/customers/me/selfie',
        {
            method: "POST",
            body: JSON.stringify({ photo: imageData, exifData, reservationId })
        }),
    checkDriversLicenseStatus: (resId: number) =>
        // NOTE: change 'testvalue=' for driver's license status in next line
        // for testing purposes add param: testvalue=0 -> rejected, testvalue=1 -> approved, testvalue=<any other value> -> Error
      fetchCustom("/customers/get-drivers-license-status?24go=1&resId=" + resId, {}),

    getAddNewCardPaytrailLink: (lang: string) =>
        fetchCustom('/customers/add-credit-card-to-paytrail?language=' + lang + '&application=24go'),
}


export const vehicle = {
    getCitiesWithVehicles: () => fetchCustom('/vehicles/count-by-city'),
}

export const payment = {
    reserveCreditCardPayment: (reservationId: number) =>
        fetchCustom('/reservations/' + reservationId + '/make-hold-for-paytrail-card',
            {method: "POST"}),


    makeCreditCardPayment: (
        reservationId: number,
    ) => fetchCustom('/reservations/' + reservationId + '/charge-paytrail-hold-card',
        {method: "POST"}),

    releasePaymentHoldAndCancelRes: (
        reservationId: number,
    ) => fetchCustom('/reservations/' + reservationId + '/release-paytrail-hold',
        {method: "POST"}),
}




const fetchCustom = (
    url: string,
    options: any = {}): Promise<any> => {

    const fullUrl = (window as any).__env.api3Root + url;
    console.log("Fetching from: ", fullUrl);

    options.headers = {
        ...options.headers,
        Authorization: "Bearer " + store.getState().login.jwt
    }

    if (options?.method === "POST" || options?.method === "PATCH") {
        options.headers = {
            ...options.headers,
            Accept: "application/json",
            "Content-Type": "application/json"
        };
        if (!options.body) {
            options.body = JSON.stringify({});
        }
    }

    return fetch(fullUrl, options)
        .then(res => {
            const fetchError = statusCheck(res, false, options?.body ? JSON.stringify(options.body).slice(0, 1024) : undefined);
            if (fetchError) {
                return fetchError;
            }

            const contentType = res.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return res.json().then(result => {
                    return result.data;
                });
            } else {
                return res;
            }
        })
        .catch(err => {
            console.log("Failed fetch ", err);
            console.log(err);
            return new Promise((resolve, rej) => rej('Network: ' + err));

            // console.log('Failed fetch ', res);
            // return new Promise((resolve, rej) => rej(res: any));

            // return this.fetchCustom(url, options, isJson, addAuthHeader, retries + 1);
        });
};

const statusCheck = (res: any, isJson: boolean, bodyStart?: string) => {
    if (res.status !== 200 && res.status !== 201 && res.status !== 204) {
        console.log("RES ERROR: ", res.status);
        return res.json().then((result: any) => {
            console.log("res: ", result);
            return new Promise((resolve, reject) =>
                reject(
                    result.data?._errorMessage === ""
                        ? result.data._error + (result.data.body ? JSON.stringify(result.data.body) : '') + (bodyStart ? bodyStart : '')
                        : result.data?._error + " - " + result.data?._errorMessage + (result.data?.body ? JSON.stringify(result.data?.body || {}) : '') + (bodyStart ? bodyStart : '')
                )
            );
        });
    }
    return undefined;
};




