import { store } from "../reduxStore";

interface AnalyticsObject {
  category: string;
  action: string;
  label: string;
  value?: number;
}

export class analyticsService {
  static logEvent(
    action: string,
    label: string = "",
    error?: any,
    value?: number
  ): void {
    if (
        
      store.getState().app.API_URL.includes("stag") ||
      store.getState().app.API_URL.includes("test")
    ) {
      return;
    }

    if (error && typeof error !== "string") {
      error = "[non-string supplied as error]";
    }

    let res = store.getState().map.reservation;
    let resString = "";
    if (res && (res.active == 0 || res.active === 1)) {
      resString = " [Reservation ID: " + res.id + "]";
    }

    let eventObject: AnalyticsObject = {
      category: store.getState().map.phase ? store.getState().map.phase : "n/a",
      action,
      label: label + (error !== undefined ? " - " + error : "") + resString
    };

    if (value) {
      eventObject.value = value;
    }

    ((window as any).dataLayer || []).push(
      Object.assign(
        {
          event: "CustomEvent"
        },
        eventObject
      )
    );
  }
}
