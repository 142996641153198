// structure of Redux store
import {User} from "./interfaces/user";
import {Parking} from "./interfaces/parking";
import {Reservation} from "./interfaces/reservation";
import {City} from "./interfaces/city";
import sibService from "./services/sibService";
import {combineReducers, createStore} from "redux";
import {Car, ParkingInstructions} from "./interfaces/car";
import { IQualification, V3User } from "./interfaces/v3/user";

export interface GlobalStore {
    app: {
        deviceLanguage: string;
        browser: {},
        mockAPI: boolean,
        developmentVersion: boolean,
        API_URL: string;
    },
    login: {
        dbToken: string | undefined;
        jwt: string | undefined;
        customerSupport: boolean;
    },
    map: {
        user: User | undefined,
        v3user: V3User | undefined,
        qualification: IQualification | undefined,
        userPhoto: string | Blob,
        apiBusy: boolean,
        autoAssigned: boolean,
        parkingReserved: Parking | undefined,
        doorsOpen: boolean,
        zoom: number,
        resInProcess: boolean,
        reservation: Reservation | undefined,
        reservations: Reservation[] | undefined,
        activeCar: number | undefined,
        cars: any,
        timeout: number,
        timerId: null,
        resLoaded: false,
        anonymousMode: false,
        phase: "",
        issue: "",
        fakeLocation: "",
        mapStyle: "",
        crmService: sibService | null,
        parkingInstructions: ParkingInstructions | null,
        janitorMode: string,
        janitorAllowed: boolean,
        cities: City[],
        carsPerCity: any,
        previousMode: string | undefined,
        carsAlreadyLoaded: {},
        parkingLot: [],
        parkingZones: {
            helsinkiZArea: {
                polygons: [[]],
            },
        },
        taskTemplates:{},
        carTrailPositions: [] | null,
        newTaskCreated: number | undefined,
        taskHistory: [],
        updateTaskHistory: boolean,
        notPickedUpRes: [],
        reservationsHistory: [],
        TOS: [],
        tosAcceptanceRequired: boolean;
    }
}

// ACTIONS:
//////LOGIN ACTIONS
type ActionStoreAuth = {
    type: 'STORE_AUTH';
    payload: {
        token: string,
        jwt: string
    };
}

type ActionSetService = {
    type: "SET_SERVICE";
}

type ActionLogout = {
    type: "LOGOUT";
}

type ActionSetAnonymous = {
    type: "SET_ANONYMOUS"
    payload: boolean | undefined;
}

//////APP ACTIONS
type ActionAppLogOut = {
    type: "LOGOUT"
}

type ActionSetLang = {
    type: "SET_LANGUAGE";
    payload: string;
}

type ActionSetBrowser = {
    type: "SET_BROWSER";
    payload: any;
}

type ActionSetVersion = {
    type: "SET_VERSION";
    payload: any;
}

type ActionSetTOSAcceptanceRequired = {
    type: "SET_TOS_ACCEPTANCE_REQUIRED";
    payload: boolean;
}


/////MAP ACTIONS:
type ActionStoreUser = {
    type: "STORE_USER";
    payload: User;
}

type ActionStoreV3User = {
    type: "STORE_V3USER";
    payload: V3User;
}

type ActionStoreQualification = {
    type: "STORE_QUALIFICATION";
    payload: IQualification;
}

type ActionStoreUserPhoto = {
    type: "STORE_USER_PHOTO";
    payload: Blob;
}

type ActionStoreTOS = {
    type: "STORE_TOS";
    payload: [];
}

type ActionSetCar = {
    type: "SET_CAR";
    payload: Car;
}

type ActionSetCars = {
    type: "SET_CARS";
    payload: any;
}

type ActionStoreReservation = {
    type: "STORE_RESERVATION";
    payload: Reservation | undefined;
}

type ActionSetReservations = {
    type: "SET_RESERVATIONS";
    payload: Reservation[];
}

type ActionCancelReservation = {
    type: "CANCEL_RESERVATION";
    payload: any;
}

type ActionSetJanitorAllowed = {
    type: "SET_JANITOR_ALLOWED";
    payload: boolean;
}

type ActionSetJanitorMode = {
    type: "SET_JANITOR";
    payload: string;
}

type ActionSetCarsForCities = {
    type: "SET_CARS_FOR_CITIES";
    payload: {};
}

type ActionSetPreviousCitiesLoaded = {
    type: "PREVIOUS_CITIES_LOADED";
    payload: {};
}

type ActionSetParkingLot = {
    type: "SET_PARKINGLOT";
    payload: [];
}

type ActionSetParkingZones = {
    type: "SET_PARKING_ZONES";
    payload: {};
}

type ActionSetCarTrail = {
    type: "SET_CAR_TRAIL";
    payload: [];
}

type ActionStoreNewTaskCreated = {
    type: "STORE_NEW_TASK_CREATED";
    payload: number | undefined;
}

type ActionStoreTaskHistory = {
    type: "STORE_TASK_HISTORY";
    payload: [] | undefined;
}

type ActionSetNotPickedUpRes = {
    type: "SET_NOT_PICKED_UP_RES";
    payload: Reservation[];
}

type ActionSetReservationsHistory = {
    type: "SET_RESERVATIONS_HISTORY";
    payload: Reservation[];
}

type ActionSetZoomMap = {
    type: "SET_MAP_ZOOM";
    payload: any;
}

type ActionCancelParking = {
    type: "CANCEL_PARKING";
}

type ActionUpdateReservation = {
    type: "UPDATE_RESERVATION";
    payload: any;
}

type ActionStoreTaskTemplates = {
    type: "STORE_TASK_TEMPLATES";
    payload: any;
}

type ActionSetParkingAutoAssigned = {
    type: "SET_PARKING_AUTO_ASSIGNED";
    payload: boolean;
}

type ActionSetParkingReserved = {
    type: "SET_PARKING_RESERVED";
    payload: any;
}

type ActionDoorChange = {
    type: "DOOR_CHANGE";
    payload: boolean;
}

type ActionResInProgress = {
    type: "RES_IN_PROCESS";
    payload: boolean;
}

type ActionSetTimeout = {
    type: "SET_TIMEOUT";
    payload: number;
}

type ActionSetTimeId = {
    type: "SET_TIMER_ID";
    payload: any;
}

type ActionResLoaded = {
    type: "RES_LOADED";
    payload: boolean;
}

type ActionSetPhase = {
    type: "SET_PHASE";
    payload: string;
}

type ActionSetIssue = {
    type: "SET_ISSUE";
    payload: string;
}

type ActionSetCities = {
    type: "SET_CITIES";
    payload: any;
}

type ActionSetFakeLocation = {
    type: "SET_FAKELOCATION";
    payload: any;
}

type ActionSetMapStyle = {
    type: "SET_MAPSTYLE";
    payload: string;
}

type ActionSetCRM = {
    type: "SET_CRM";
    payload: sibService | null;
}

type ActionStoreParkingInstructions = {
    type: "STORE_PARKING_INSTRUCTIONS";
    payload: any;
}

type ActionPreviousMode = {
    type: "PREVIOUS_MODE";
    payload: string;
}

// Actions Merged:
type Action =
    | ActionStoreAuth
    | ActionSetService
    | ActionLogout
    | ActionSetAnonymous
    | ActionAppLogOut
    | ActionSetLang
    | ActionSetBrowser
    | ActionSetVersion
    | ActionStoreUser
    | ActionStoreV3User
    | ActionStoreQualification
    | ActionStoreUserPhoto
    | ActionStoreTOS
    | ActionSetCar
    | ActionSetCars
    | ActionStoreReservation
    | ActionSetReservations
    | ActionCancelReservation
    | ActionSetJanitorAllowed
    | ActionSetJanitorMode
    | ActionSetCarsForCities
    | ActionSetPreviousCitiesLoaded
    | ActionSetParkingLot
    | ActionSetParkingZones
    | ActionSetCarTrail
    | ActionStoreNewTaskCreated
    | ActionStoreTaskHistory
    | ActionSetNotPickedUpRes
    | ActionSetReservationsHistory
    | ActionSetZoomMap
    | ActionCancelParking
    | ActionUpdateReservation
    | ActionStoreTaskTemplates
    | ActionSetParkingAutoAssigned
    | ActionSetParkingReserved
    | ActionDoorChange
    | ActionResInProgress
    | ActionSetTimeout
    | ActionSetTimeId
    | ActionResLoaded
    | ActionSetPhase
    | ActionSetIssue
    | ActionSetCities
    | ActionSetFakeLocation
    | ActionSetMapStyle
    | ActionSetCRM
    | ActionStoreParkingInstructions
    | ActionPreviousMode
    | ActionSetTOSAcceptanceRequired;


export type Dispatch = (action: Action) => void;


const INITIAL_STATE: GlobalStore = {
    app: {
        deviceLanguage: window.localStorage.getItem('language') || "fi",
        browser: {},
        mockAPI: false,
        developmentVersion: !window.location.href.includes('//drive-24go'),
        API_URL: (window as any).__env.apiRoot,
    },
    map: {
        user: undefined,
        v3user: undefined,
        qualification: undefined,
        userPhoto: "",
        apiBusy: false,
        autoAssigned: false,
        parkingReserved: undefined,
        doorsOpen: false,
        zoom: 13,
        resInProcess: false,
        reservation: undefined, //currently used reservation
        reservations: [],
        activeCar: undefined,
        cars: {},
        timeout: 15,
        timerId: null,
        resLoaded: false,
        anonymousMode: false,
        phase: "",
        issue: "",
        fakeLocation: "",
        mapStyle: "",
        crmService: null,
        parkingInstructions: null,
        janitorMode: localStorage.getItem('GoNowJanitor') || 'customer',
        janitorAllowed: false,
        cities: [],
        carsPerCity: {},
        previousMode: undefined,
        carsAlreadyLoaded: {},
        parkingLot: [],
        parkingZones: {
            helsinkiZArea: {
                polygons: [[]],
            },
        },
        taskTemplates:{},
        carTrailPositions:null,
        newTaskCreated: undefined,
        taskHistory: [],
        updateTaskHistory: true,
        notPickedUpRes: [],
        reservationsHistory: [],
        TOS: [],
        tosAcceptanceRequired: false,
    },
    login: {
        dbToken: localStorage.getItem("GoNowToken") || "",
        jwt: localStorage.getItem("GoNowStore") || "",
        customerSupport: false
    }
}

const loginReducer = (state = INITIAL_STATE.login, action: Action) => {
    switch (action.type) {
        case "LOGOUT": {
            sessionStorage.removeItem('pc-Qual');
            sessionStorage.removeItem('toastMsg');
            return { ...state, dbToken: "", jwt: "" };
        }
        case "STORE_AUTH": {
            return {
                ...state,
                dbToken: action.payload.token,
                jwt: action.payload.jwt
            };
        }
        case "SET_SERVICE": {
            // console.log("SET_SERVICE: Customer support enabled!");
            return {
                ...state,
                customerSupport: true
            };
        }

        case "SET_ANONYMOUS": {
            console.log(
                // "SET_ANONYMOUS reduced in Login duck! Value: ",
                action.payload
            );
            return {
                ...state,
                dbToken: action.payload ? "anonymous" : ""
            };
        }

        default:
            return state;
    }
}

const appReducer = (state = INITIAL_STATE.app, action: Action) => {
    switch(action.type) {
        case "LOGOUT":
            sessionStorage.removeItem('pc-Qual');
            sessionStorage.removeItem('toastMsg');
        {
            return INITIAL_STATE.app;
        }

        case "SET_LANGUAGE": {
            try {
                window.localStorage.setItem('language', action.payload);
            } catch (e) { console.log('no localstorage'); } //dont crash on error
            return {
                ...state,
                deviceLanguage: action.payload
            };
        }
        case "SET_BROWSER": {
            return {
                ...state,
                browser: action.payload
            };
        }

        case "SET_VERSION": {
            return {
                ...state,
                version: action.payload
            };
        }

        default:
            return state;
    }
}

const mapReducer = (state = INITIAL_STATE.map, action: Action) => {
    switch (action.type) {
        case "LOGOUT": {
            sessionStorage.removeItem('pc-Qual');
            sessionStorage.removeItem('toastMsg');
            return { ...state, crmService: state.crmService };
        }

        case "SET_TOS_ACCEPTANCE_REQUIRED": {
            return {
                ...state,
                tosAcceptanceRequired: action.payload
            };
        }

        case "STORE_USER": {
            return {
                ...state,
                tosAcceptanceRequired: action.payload?.hasAcceptedTwoFourGoTOS === false,
                user: action.payload
            };
        }

        case "STORE_V3USER": {
            return {
                ...state,
                v3user: action.payload
            };
        }

        case "STORE_QUALIFICATION": {
            sessionStorage.setItem('pc-Qual', JSON.stringify(action.payload));
            return {
                ...state,
                qualification: action.payload
            };
        }

        case "STORE_USER_PHOTO": {
            return {
                ...state,
                userPhoto: action.payload
            };
        }

        case "STORE_TOS": {
            return {
                ...state,
                TOS: action.payload
            };
        }

        case "STORE_RESERVATION": {
            let newReservations = state.reservations;
            if(action.payload &&
                (action.payload.active === 0 || action.payload.active === 1) ) { //only add if in reserved state
                // @ts-ignore
                newReservations = [...state.reservations];
                const index=state.reservations?.findIndex(r=>r.id===action.payload?.id) ?? -1;
                if(index>=0) {
                    newReservations[index] = action.payload;
                }
                else {
                    newReservations.push(action.payload);
                }

            }

            return {
                ...state,
                reservation: action.payload,
                reservations: newReservations
            };
        }

        case "SET_RESERVATIONS": {
            return {
                ...state,
                reservations: action.payload || []
            };
        }

        case "SET_CAR": {
            if (!action.payload) {
                return {
                    ...state,
                    activeCar: null
                };
            }
            const newCars = { ...state.cars };
            newCars[action.payload.id] = action.payload;

            return {
                ...state,
                cars: newCars,
                activeCar: action.payload.id
            };
        }

        case "SET_CARS": {
            return {
                ...state,
                cars: action.payload
            };
        }

        case "CANCEL_RESERVATION": {
            let newReservations = state.reservations;
            if(action.payload)
                newReservations = newReservations?.filter(r=>r.id!==action.payload.id); //remove from list

            return {
                ...state,
                reservation: action.payload,
                parkingReserved: undefined,
                autoAssigned: false,
                reservations: newReservations
            };
        }

        case "SET_MAP_ZOOM": {
            return {
                ...state,
                zoom: action.payload
            };
        }

        case "CANCEL_PARKING": {
            return {
                ...state,
                parkingReserved: undefined,
                autoAssigned: false
            };
        }

        case "UPDATE_RESERVATION": {

            let newReservations = state.reservations;
            if(action.payload &&
                (action.payload.active === 0 || action.payload.active === 1) ) { //only add if in reserved state
                // @ts-ignore
                newReservations = [...state.reservations];
                const index=state.reservations?.findIndex(r=>r.id===action.payload.id);
                if(index && index>=0)
                    newReservations[index ] = action.payload;
                else
                    newReservations.push(action.payload);
            }

            return {
                ...state,
                reservation: action.payload,
                parkingReserved:
                    action.payload.active === 2 || action.payload.active === -1
                        ? undefined
                        : state.parkingReserved,
                autoAssigned:
                    action.payload.active === 2 || action.payload.active === -1
                        ? false
                        : state.autoAssigned,
                reservations: newReservations
            };
        }

        case "SET_PARKING_AUTO_ASSIGNED": {
            return {
                ...state,
                autoAssigned: action.payload
            };
        }

        case "SET_PARKING_RESERVED": {

            return {
                ...state,
                parkingReserved: action.payload ? action.payload : undefined,
                autoAssigned: action.payload ? state.autoAssigned : false
            };
        }

        case "DOOR_CHANGE": {
            return {
                ...state,
                doorsOpen: action.payload
            };
        }

        case "RES_IN_PROCESS": {
            return {
                ...state,
                resInProcess: action.payload
            };
        }

        case "SET_TIMEOUT": {
            return {
                ...state,
                timeout: action.payload
            };
        }

        case "SET_TIMER_ID": {
            if (action.payload === null){
                if(state.timerId) {
                    clearInterval(state.timerId);
                }
            } else if (state.timerId !== null) {
                return state;
            }

            return {
                ...state,
                timerId: action.payload
            };
        }

        case "RES_LOADED": {
            return {
                ...state,
                resLoaded: action.payload
            };
        }

        case "SET_ANONYMOUS": {
            return {
                ...state,
                anonymousMode: action.payload
            };
        }

        case "SET_PHASE": {
            return {
                ...state,
                phase: action.payload
            };
        }

        case "SET_ISSUE": {
            return {
                ...state,
                issue: action.payload
            };
        }

        case "SET_CITIES": {
            return {
                ...state,
                cities: action.payload
            };
        }

        case "SET_FAKELOCATION": {
            return {
                ...state,
                fakeLocation: action.payload
            };
        }

        case "SET_MAPSTYLE": {
            return {
                ...state,
                mapStyle: action.payload
            };
        }

        case "SET_CRM": {
            return {
                ...state,
                crmService: action.payload
            };
        }

        case "SET_JANITOR": {
            return {
                ...state,
                janitorMode: action.payload
            };
        }

        case "SET_JANITOR_ALLOWED": {
            return {
                ...state,
                janitorAllowed: action.payload
            };
        }

        case "STORE_PARKING_INSTRUCTIONS": {
            return {
                ...state,
                parkingInstructions: action.payload
            };
        }

        case "SET_CARS_FOR_CITIES": {
            return {
                ...state,
                carsPerCity: action.payload
            };
        }

        case "PREVIOUS_MODE": {
            return {
                ...state,
                previousMode: action.payload
            };
        }

        case "PREVIOUS_CITIES_LOADED": {
            return {
                ...state,
                carsAlreadyLoaded: action.payload
            };
        }

        case "SET_PARKINGLOT": {
            return {
                ...state,
                parkingLot: action.payload
            };
        }

        case "SET_PARKING_ZONES": {
            return {
                ...state,
                parkingZones: action.payload
            };
        }

        case "STORE_TASK_TEMPLATES": {
            return {
                ...state,
                taskTemplates: action.payload
            };
        }

        case "SET_CAR_TRAIL": {
            let positions = null;

            if (action.payload!==null){
                positions = action.payload.reverse().map(e =>{return [e[1],e[0]];});
            }

            return {
                ...state,
                carTrailPositions: positions
            };
        }

        case "STORE_NEW_TASK_CREATED": {
            return {
                ...state,
                newTaskCreated: action.payload
            };
        }

        case "STORE_TASK_HISTORY": {
            return {
                ...state,
                taskHistory: action.payload || []
            };
        }

        case "SET_NOT_PICKED_UP_RES": {
            return {
                ...state,
                notPickedUpRes: action.payload || []
            };
        }

        case "SET_RESERVATIONS_HISTORY": {
            return {
                ...state,
                reservationsHistory: action.payload || []
            };
        }

        default:
            return state;
    }
}

const combinedReducer = combineReducers({
    map: mapReducer,
    login: loginReducer,
    app: appReducer,
});

export const store = createStore(combinedReducer, undefined);

