import * as React from "react";
import Modal from "react-modal";

import "./apiModal.css";

const customStyles = {
  content: {
    width: "auto",
    minWidth: "200px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0,0,0,0.7)",
    color: "white"
  },
  overlay: {
    zIndex: '10000'
  }
};

interface ApiModalProps {
  isOpen: boolean;
  message?: string;
}

interface ApiModalState {}

class ApiModal extends React.Component<ApiModalProps, ApiModalState> {
  componentDidMount() {
    Modal.setAppElement("body");
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="loader" />
        <div className="apiMessage">
          {this.props.message ? <h2>{this.props.message}</h2> : null}
        </div>
      </Modal>
    );
  }
}
export default ApiModal;
