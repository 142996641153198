import * as React from 'react';
import Modal from 'react-modal';

import './ResponseModal.scss';

interface ResponseModalProps {
  response: {
    open: boolean;
    text: string;
    buttonText: string;
  };
  // isOpen: boolean;
  closeFunc: () => void;
  //   buttonText: string;
}

interface ResponseModalState { }

const customStyles: Modal.Styles = {
  content: {
    width: '220px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0,0,0,0.7)',
    color: 'white'
  },
  overlay: {
    zIndex: '10000'
  }
};

class ResponseModal extends React.Component<ResponseModalProps, ResponseModalState> {
  componentDidMount() {
    Modal.setAppElement('body');
  }
  render() {
    //console.log("props: ", this.props);
    if (this.props.response.text.includes('</a>')) {
      return (
        <Modal isOpen={this.props.response.open} style={customStyles} contentLabel="Response Modal">
          <div className="ResponseText">
            <p dangerouslySetInnerHTML={{__html: this.props.response.text}} />
            <br />
            <button className="btn-lg normal" onClick={() => this.props.closeFunc()}>
              {this.props.response.buttonText}
            </button>
          </div>
        </Modal>
      );
    } else {
      return (
        <Modal isOpen={this.props.response.open} style={customStyles} contentLabel="Response Modal">
          <div className="ResponseText">
            {this.props.response.text}
            <br />
            <button className="btn-lg normal" onClick={() => this.props.closeFunc()}>
              {this.props.response.buttonText}
            </button>
          </div>
        </Modal>
      );
    }
  }
}
export default ResponseModal;
